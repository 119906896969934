import { getHardLabelConfigFromTitle } from 'common/ontology'
import { HardLabel, LabelsPagination } from 'common/types'

type Props = {
  duration: number
  hardLabels: HardLabel[]
  labelsPagination: LabelsPagination
}

export const HardLabelsPreview = ({
  duration,
  hardLabels,
  labelsPagination,
}: Props) => {
  return (
    <div className="relative flex flex-grow">
      <div className="absolute bottom-0 left-0 right-0 top-0 z-10 flex">
        {hardLabels.map((hardLabel, idx) => (
          <div
            key={idx}
            className={`absolute bottom-0 top-0 flex flex-row ${
              getHardLabelConfigFromTitle(labelsPagination, hardLabel.label)
                .color
            } items-center justify-center bg-opacity-50`}
            style={{
              left: `${(hardLabel.start / duration) * 100}%`,
              right: `${100 - (hardLabel.end / duration) * 100}%`,
            }}
          >
            <span className="truncate p-2 text-sm font-semibold capitalize">
              {hardLabel.label}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}
