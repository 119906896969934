import { soundKey, toParticle } from 'common/soundKey'
import { LabeledSound, Review } from 'common/types'
import { limitToLast, onValue, query, ref } from 'firebase/database'
import { useCallback, useEffect, useState } from 'react'
import { onError } from 'shared/utils/web/error'
import { database } from '../firebase'
import { update } from '../firebaseMethods'

export interface SoundToReview {
  key: string
  path: string
}

function soundToReviewFromURL() {
  const pathParts = (window.location.href.split('=')[1] || '').split('/')
  const serial = pathParts[0]
  const date = pathParts[1]
  const name = pathParts[2]

  if (!serial || !date || !name) return undefined

  const key = soundKey(serial, date, name)

  const soundFromURL: SoundToReview = {
    key,
    path: toParticle(serial, date, name),
  }

  return soundFromURL
}

export const useLabeledSound = () => {
  const BATCH_SIZE = 50
  const [soundFromURL, setSoundFromURL] = useState(soundToReviewFromURL)
  const [fetchSize, setFetchSize] = useState<number>(BATCH_SIZE)
  const [soundsToReview, setSoundsToReview] = useState<SoundToReview[] | null>(
    null,
  )
  const [reviewedSounds, setReviewedSounds] = useState<SoundToReview[]>([])
  const [currentSoundIndex, setCurrentSoundIndex] = useState<number | null>(
    null,
  )

  // Retrieve soundsToReview from database, expanding fetchSize as needed
  useEffect(() => {
    if (soundFromURL) {
      setSoundsToReview([soundFromURL])
      return
    }

    const labeledSoundsQuery = query(
      ref(database, 'labeledSounds'),
      limitToLast(fetchSize),
    )

    const unsubscribe = onValue(
      labeledSoundsQuery,
      (snapshot) => {
        const soundCandidates: SoundToReview[] = []

        Object.entries<LabeledSound>(snapshot.val() || {}).forEach(
          ([key, sound]) => {
            const { serial, date, name, labelers, review } = sound

            if (
              // only consider sounds not reviewed yet
              review === undefined &&
              labelers !== undefined
            ) {
              const path = toParticle(serial, date, name)
              soundCandidates.push({ key, path })
            }
          },
        )

        if (soundCandidates.length >= BATCH_SIZE || snapshot.size < fetchSize) {
          setSoundsToReview(soundCandidates)
        } else {
          setFetchSize(fetchSize + BATCH_SIZE)
        }
      },
      onError,
    )

    return unsubscribe
  }, [fetchSize, soundFromURL])

  const randomlyPickSoundToReview = useCallback(() => {
    if (soundsToReview === null) return false

    // Prevent selecting the sound that has just been labeled, and is still available in soundsToLabel
    const reviewedSoundsKeys = reviewedSounds.map(({ key }) => key)
    const availableSounds = soundsToReview.filter(
      ({ key }) => !reviewedSoundsKeys.includes(key),
    )

    if (availableSounds.length === 0) return false

    const randomIndex = Math.floor(Math.random() * availableSounds.length)
    const nextSound = availableSounds[randomIndex]
    setReviewedSounds([...reviewedSounds, nextSound])
    setCurrentSoundIndex(reviewedSounds.length)

    return true
  }, [reviewedSounds, soundsToReview])

  // Check if there are more sounds available. At start and possibly after
  useEffect(() => {
    if (currentSoundIndex === null) randomlyPickSoundToReview()
  }, [currentSoundIndex, randomlyPickSoundToReview])

  const saveReview = useCallback(
    async (review: Review): Promise<void> => {
      if (currentSoundIndex === null)
        throw Error('saveReview called with null sound index')

      const soundToReview = reviewedSounds[currentSoundIndex]

      await update(`labeledSounds/${soundToReview.key}`, { review }).catch(
        onError,
      )

      // Automatically move to next sound
      if (soundFromURL) {
        setSoundFromURL(undefined)
        setCurrentSoundIndex(null)
      } else if (currentSoundIndex === reviewedSounds.length - 1) {
        if (!randomlyPickSoundToReview())
          // Done reviewing all files
          setCurrentSoundIndex(null)
      } else {
        // When updating previous saved labels, shift to next on save
        setCurrentSoundIndex(currentSoundIndex + 1)
      }
    },
    [
      currentSoundIndex,
      randomlyPickSoundToReview,
      reviewedSounds,
      soundFromURL,
    ],
  )

  const prev = useCallback(() => {
    setCurrentSoundIndex((currentSoundIndex) => {
      if (currentSoundIndex === null)
        throw Error('Prev called with null sound index')
      return currentSoundIndex - 1
    })
  }, [])

  const next = useCallback(() => {
    setCurrentSoundIndex((currentSoundIndex) => {
      if (currentSoundIndex === null)
        throw Error('Next called with null sound index')
      return currentSoundIndex + 1
    })
  }, [])

  const soundToReview =
    currentSoundIndex === null ? null : reviewedSounds[currentSoundIndex]

  return {
    loading: soundsToReview === null,
    soundToReview,
    saveReview,
    prevDisabled: currentSoundIndex === null || currentSoundIndex < 1,
    nextDisabled:
      currentSoundIndex === null ||
      currentSoundIndex >= reviewedSounds.length - 1,
    prev,
    next,
  }
}
