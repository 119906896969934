export const s3BucketRegion = 'eu-west-3'
export const dynamoDBRegion = 'eu-west-3'

export const s3BucketName = 'oso-sounds'

export type AwsCredentials = {
  accessKeyId: string
  secretAccessKey: string
}

export type ClientConfig = {
  endpoint?: string
} & AwsCredentials
