import { useEffect, useLayoutEffect, useState } from 'react'
import { fetch_ } from 'shared/utils/fetch'
import { MAX_VOL, computeAdmissibleVolume } from 'shared/utils/web/audio'
import { draw, filterData, normalizeData } from '../utils'

const MAX_DURATION = 9.62

export const useAudioPlayer = ({
  src,
  autoplay,
  playbackRate,
  canvasRef,
  audioElementRef,
  audioDuration,
}: {
  src: string
  autoplay: boolean
  playbackRate: number
  canvasRef: React.RefObject<HTMLCanvasElement>
  audioElementRef: React.RefObject<HTMLAudioElement>
  audioDuration: number
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [progression, setProgression] = useState(0)
  const [volume, setVolume] = useState(MAX_VOL)
  const [admissibleVolume, setAdmissibleVolume] = useState(MAX_VOL)

  useLayoutEffect(() => {
    if (audioElementRef.current === null || canvasRef.current === null) return
    const audioElement = audioElementRef.current
    // Polyfill for old versions of Chrome

    const AudioContext =
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      window.AudioContext || (window as any).webkitAudioContext

    const audioContext = new AudioContext()

    const canvas = canvasRef.current
    setIsLoaded(false)
    /**
     * Retrieves audio from an external source, the initializes the drawing function
     * @param {String} url the url of the audio we'd like to fetch
     */
    const drawAudio = (url: string) => {
      fetch_(url)
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => audioContext.decodeAudioData(arrayBuffer))
        .then((audioBuffer) => {
          if (audioBuffer === undefined)
            throw Error('Unable to decode audio data')
          const newAdmissibleVolume = computeAdmissibleVolume(audioBuffer)
          setAdmissibleVolume(newAdmissibleVolume)
          audioElement.volume = newAdmissibleVolume
          draw(
            normalizeData(
              filterData(audioBuffer, Math.round(canvas.offsetWidth / 2)),
            ),
            canvas,
          )
        })
        .catch((error) => {
          console.error('Audio error', error)
          // https://github.com/facebook/react/issues/14981
          setIsPlaying(() => {
            throw new Error("Ce son n'a pas pu être lu")
          })
        })
    }

    drawAudio(src)

    const canPlayHandler = () => {
      setIsLoaded(true)
      if (autoplay) {
        audioElement.play()
      }
    }

    const playHandler = () => {
      setIsPlaying(true)
    }

    const pauseHandler = () => {
      setIsPlaying(false)
    }

    const errorHandler = () => {
      console.error('Audio error', src)
      setIsPlaying(() => {
        throw new Error("Ce son n'a pas pu être trouvé")
      })
    }

    const volumeChangeHandler = () => {
      setVolume(audioElement.volume)
    }

    audioElement.addEventListener('canplay', canPlayHandler)
    audioElement.addEventListener('play', playHandler)
    audioElement.addEventListener('pause', pauseHandler)
    audioElement.addEventListener('error', errorHandler)
    audioElement.addEventListener('volumechange', volumeChangeHandler)

    return () => {
      canvas.getContext('2d')?.clearRect(0, 0, canvas.width, canvas.height)
      audioElement.pause()
      audioElement.removeEventListener('canplay', canPlayHandler)
      audioElement.removeEventListener('play', playHandler)
      audioElement.removeEventListener('pause', pauseHandler)
      audioElement.removeEventListener('error', errorHandler)
      audioElement.removeEventListener('volumechange', volumeChangeHandler)
      setProgression(0)
    }
  }, [canvasRef, audioElementRef, src, autoplay])

  useEffect(() => {
    if (audioElementRef.current === null) return
    // Playback rate is reset everytime src is changed otherwise
    audioElementRef.current.playbackRate = playbackRate
  }, [audioElementRef, src, playbackRate])

  useLayoutEffect(() => {
    if (audioElementRef.current === null) return
    audioElementRef.current.playbackRate = playbackRate
  }, [audioElementRef, playbackRate])

  useLayoutEffect(() => {
    if (audioElementRef.current === null) return
    const audioElement = audioElementRef.current

    let currentAnimationFrame: number

    const updateProgression = () => {
      setProgression((audioElement.currentTime / audioDuration) * 100)
      if (audioElement.currentTime > MAX_DURATION) audioElement.pause()
      currentAnimationFrame = requestAnimationFrame(updateProgression)
    }

    updateProgression()

    return () => cancelAnimationFrame(currentAnimationFrame)
  }, [audioElementRef, audioDuration])

  return { isPlaying, isLoaded, progression, volume, admissibleVolume }
}
