import { LABEL_TEXT, LabelKey, labelsPaginationMap } from 'common/ontology'
import { addOggExtension } from 'common/soundKey'
import {
  HardLabel,
  LabelProposal,
  Labelers,
  Review as ReviewType,
  SubLabelKey,
  Users,
} from 'common/types'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { isObjectEmpty } from 'shared/utils/defined'
import { ms2sec } from 'shared/utils/time'
import { siteUrl } from 'shared/utils/url'
import { Deferred } from 'shared/utils/web/deferred'
import { HardLabelsPreview } from './HardLabelsPreview'
import { labelingConfigMap } from './Labeling'
import { Player } from './Player'
import { Button } from './components'
import { DIALOG_CLOSED_REASON } from './components/Dialog'
import { ReviewCommentDialog } from './components/ReviewCommentDialog'
import { auth } from './firebase'
import { get } from './firebaseMethods'
import { useLabeledSound } from './hooks/useLabeledSound'
import { getFilePathTime } from './utils'

interface Props {
  getUrl: (path: string) => Promise<string>
}

function listenUrl(path: string) {
  return `${siteUrl('listen')}/${path}`
}

export const Review = ({ getUrl }: Props) => {
  const { playbackRates } = labelingConfigMap[import.meta.env.VITE_ONTOLOGY]
  const labelsPagination = labelsPaginationMap[import.meta.env.VITE_ONTOLOGY]

  const [firstSoundHasBeenReviewed, setFirstSoundHasBeenReviewed] =
    useState(false)
  const [reviewCommentDialogDeferred, setReviewCommentDialogDeferred] =
    useState<Deferred<string> | null>(null)
  const [users, setUsers] = useState<Users | null>(null)
  const [labelers, setLabelers] = useState<Labelers>({})
  const [soundUrl, setSoundUrl] = useState<string | undefined>(undefined)

  const {
    loading,
    soundToReview,
    saveReview,
    prevDisabled,
    nextDisabled,
    prev,
    next,
  } = useLabeledSound()

  useEffect(() => {
    async function getUsers() {
      const users = await get('users')
      setUsers(users)
    }
    getUsers()
  }, [])

  useEffect(() => {
    async function getLabelers() {
      if (soundToReview) {
        const labelers =
          (await get(`labeledSounds/${soundToReview.key}/labelers`)) || {}
        setLabelers(labelers)
      }
    }
    getLabelers()
  }, [soundToReview])

  useEffect(() => {
    if (soundToReview === null) return

    window.history.replaceState(
      null,
      '',
      `?review=${soundToReview.path.replace('.ogg', '')}`,
    )
  }, [soundToReview])

  useEffect(() => {
    async function fetchUrl() {
      if (soundToReview === null) {
        setSoundUrl(undefined)
      } else {
        const soundUrl = await getUrl(addOggExtension(soundToReview.path))
        setSoundUrl(soundUrl)
      }
    }

    fetchUrl()
  }, [soundToReview, getUrl])

  const softLabelsMatrix = useMemo(() => {
    let atLeastOneVote: (LabelKey | SubLabelKey | LabelProposal)[] = []
    if (soundToReview) {
      if (labelers) {
        for (const labeling of Object.values(labelers)) {
          for (const label of labeling.labels ?? []) {
            if (
              !('start' in label) &&
              !('end' in label) &&
              !atLeastOneVote.includes(label.label)
            ) {
              atLeastOneVote.push(label.label)
            }
          }
        }
      }
    }
    atLeastOneVote = atLeastOneVote.sort((a, b) => a.localeCompare(b))

    const matrix: Record<string, (string | null)[]> = {}
    if (soundToReview) {
      if (labelers) {
        for (const [uid, labeling] of Object.entries(labelers)) {
          const labels = (labeling.labels ?? []).map((label) => label.label)
          matrix[uid] = atLeastOneVote.map((label) =>
            labels.includes(label) ? label : null,
          )
        }
      }
    }

    return matrix
  }, [soundToReview, labelers])

  const hardLabelsMatrix = useMemo(() => {
    const matrix: Record<string, HardLabel[]> = {}
    if (soundToReview) {
      if (labelers) {
        for (const [uid, labeling] of Object.entries(labelers)) {
          const hardLabels: HardLabel[] = []
          for (const label of labeling.labels ?? []) {
            if ('start' in label && 'end' in label) hardLabels.push(label)
          }
          matrix[uid] = hardLabels
        }
      }
    }

    return matrix
  }, [soundToReview, labelers])

  function sendReview(review: ReviewType) {
    saveReview(review)
    if (!firstSoundHasBeenReviewed) {
      setFirstSoundHasBeenReviewed(true)
    }
  }

  async function copyListenLinkToClipboard() {
    const link = listenUrl(soundToReview?.path ?? 'error')
    await navigator.clipboard.writeText(link)
    alert(
      `Le lien vers listen a été copié dans le presse papier (Ctrl + V pour l'utiliser).`,
    )
  }

  if (loading || users === null) {
    return <h2>Chargement...</h2>
  }

  if (
    users[auth.currentUser?.uid ?? 'No user logged in']?.role !== 'OSO' &&
    users[auth.currentUser?.uid ?? 'No user logged in']?.role !== 'Bootcamp' // FIXME Remove this, clean bootcamp feature (jordan)
  ) {
    return <h2>Accès refusé</h2>
  }

  if (soundToReview === null) {
    return <h2>Aucun son à contrôler</h2>
  }

  const labelTime = getFilePathTime(soundToReview.path)

  const handleComment = async () => {
    const deferred = new Deferred<string>()
    setReviewCommentDialogDeferred(deferred)
    try {
      const comment = await deferred.promise
      sendReview(comment)
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setReviewCommentDialogDeferred(null)
    }
  }

  return (
    <div className="flex min-h-screen w-screen flex-col">
      {soundUrl && (
        <Player
          labelTime={labelTime}
          src={soundUrl}
          autoplay={firstSoundHasBeenReviewed}
          mutedRanges={[]}
          playbackRates={playbackRates}
        >
          {(audioElementRef) =>
            Object.values(hardLabelsMatrix).some(
              (hardLabels) => hardLabels.length !== 0,
            ) && (
              <div className="divide-gr absolute bottom-0 left-0 right-0 top-0 z-10 flex flex-col divide-y divide-dashed">
                {Object.entries(hardLabelsMatrix).map(
                  ([uid, hardLabels]) =>
                    audioElementRef.current && (
                      <HardLabelsPreview
                        key={uid}
                        duration={audioElementRef.current.duration}
                        hardLabels={hardLabels}
                        labelsPagination={labelsPagination}
                      />
                    ),
                )}
              </div>
            )
          }
        </Player>
      )}
      <div className="flex flex-auto flex-col justify-evenly px-4 text-center text-lg font-semibold">
        {isObjectEmpty(labelers) && (
          <div className="p-2">Aucune labellisation pour ce son</div>
        )}
        {Object.entries(labelers).map(([uid, labeling]) => (
          <div key={uid}>
            <div className="my-4 flex flex-row justify-evenly rounded-md bg-gray-800">
              <div className="p-2">
                {`${
                  uid in users ? users[uid].email.split('@')[0] : uid
                } - ${ms2sec(labeling.ts - labeling.start_ts).toFixed()} s`}
              </div>
              <div className="p-2">
                {`Date de labellisation : ${DateTime.fromMillis(
                  labeling.ts,
                ).toLocaleString({
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                })}`}
              </div>
            </div>
            <div className="grid grid-cols-6 gap-2">
              {softLabelsMatrix[uid].map((label) => (
                <div
                  key={`${uid}_${label}`}
                  className={`${
                    label !== null
                      ? !label.startsWith('#')
                        ? 'bg-sky-500'
                        : 'bg-yellow-500'
                      : 'bg-gray-500/50'
                  } rounded-md text-center font-semibold`}
                >
                  {label !== null
                    ? !label.startsWith('#')
                      ? LABEL_TEXT[label.split('.').slice(-1)[0] as LabelKey]
                      : label
                    : ''}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-row flex-wrap gap-4 p-4">
        <Button
          title="Son précédent"
          disabled={prevDisabled}
          primary
          onClick={prev}
        >
          &larr;
        </Button>
        <Button
          title="Son suivant"
          disabled={nextDisabled}
          primary
          onClick={next}
        >
          &rarr;
        </Button>
        <Button
          title="Copier le lien listen"
          disabled={soundToReview === null}
          onClick={copyListenLinkToClipboard}
        >
          <span role="img" aria-label="Partager">
            📋
          </span>
        </Button>
        <a
          href={`${listenUrl(soundToReview.path)}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            title="Se rendre sur listen"
            disabled={soundToReview === null}
          >
            <span role="img" aria-label="Partager">
              👂
            </span>
          </Button>
        </a>
        <a href={soundUrl} target="_blank" rel="noopener noreferrer">
          <Button title="Télécharger le son" disabled={soundToReview === null}>
            <span role="img" aria-label="Télécharger">
              📥
            </span>
          </Button>
        </a>
        <Button
          primary
          className="flex-1 bg-red-600 hover:bg-red-800"
          onClick={handleComment}
          disabled={isObjectEmpty(labelers)}
        >
          Signaler un problème
        </Button>
        <Button
          primary
          className="flex-1 bg-green-600 hover:bg-green-800"
          disabled={isObjectEmpty(labelers)}
          onClick={() => sendReview(true)}
        >
          R.A.S.
        </Button>
      </div>
      {reviewCommentDialogDeferred && (
        <ReviewCommentDialog deferred={reviewCommentDialogDeferred} />
      )}
    </div>
  )
}
