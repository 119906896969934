import React, { MouseEvent, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Button } from '../components'

export const DIALOG_CLOSED_REASON = 'DIALOG_CLOSED_REASON'

const dialogContainer = document.createElement('div')

document.body.appendChild(dialogContainer)

export const Dialog: React.FC<
  React.PropsWithChildren<{
    title: React.ReactNode
    onClose: () => void
    [key: string]: unknown
  }>
> = ({ children, title, onClose }) => {
  const backdropRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>
  const [mouseDownEvent, setMouseDownEvent] = useState<MouseEvent | null>(null)

  return createPortal(
    <div
      className="fixed bottom-0 left-0 right-0 top-0 z-10 flex items-center justify-center bg-black/80 font-semibold sm:text-2xl"
      ref={backdropRef}
      onMouseDown={(event: MouseEvent) => {
        if (event.target === backdropRef.current) {
          setMouseDownEvent(event)
        }
      }}
      onMouseUp={(event: MouseEvent) => {
        if (event.target === backdropRef.current && mouseDownEvent) {
          onClose()
        }
        setMouseDownEvent(null)
      }}
    >
      <div
        className="bg-default m-4 flex flex-col rounded-lg p-4"
        onClick={(e) => e.stopPropagation()}
        onKeyUp={(event) => {
          event.stopPropagation()
        }}
      >
        <div className="flex">
          <div className="flex grow flex-col items-center justify-center px-4 uppercase">
            {title}
          </div>
          <Button className="rounded-full" onClick={onClose}>
            X
          </Button>
        </div>

        <div>{children}</div>
      </div>
    </div>,
    dialogContainer,
  )
}
