import { DateTime } from 'luxon'

export const shuffle = <T>(array: T[]) => {
  let currentIndex = array.length

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    const randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    const temp = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temp
  }

  return array
}

export const getFilePathTime = (path: string) => {
  const fileName = path.split('/').pop()
  if (fileName === undefined) return '?'
  const date = DateTime.fromISO(fileName.replace('.ogg', ''), {
    zone: 'Europe/Paris',
  })
  return `${date.hour}h${date.minute < 10 ? '0' : ''}${date.minute}`
}

const MIN_DB = -60
const MAX_DB = -20

/**
 * Filters the AudioBuffer retrieved from an external source
 * @param {AudioBuffer} audioBuffer the AudioBuffer from drawAudio()
 * @returns {Array} an array of floating point numbers
 */
export const filterData = (audioBuffer: AudioBuffer, nbSamples: number) => {
  const rawData = audioBuffer.getChannelData(0) // We only need to work with one channel of data
  const blockSize = rawData.length / nbSamples // the number of samples in each subdivision
  const filteredData = []
  for (let i = 0; i < nbSamples; i++) {
    const blockStart = Math.floor(blockSize * i) // the location of the first sample in the block
    let sum = 0
    for (let j = 0; j < blockSize; j++) {
      const value = Math.abs(rawData[blockStart + j])
      sum += value // find the sum of all the samples in the block
    }
    filteredData.push(20 * (Math.log(sum / blockSize) / Math.LN10))
  }
  return filteredData
}

/**
 * Normalizes the audio data to make a cleaner illustration
 * @param {Array} filteredData the data from filterData()
 * @returns {Array} an normalized array of floating point numbers
 */
export const normalizeData = (filteredData: number[]) => {
  return filteredData.map((value) =>
    Math.min(1, Math.max(0, (value - MIN_DB) / (MAX_DB - MIN_DB))),
  )
}

/**
 * Draws the audio file into a canvas element.
 * @param {Array} normalizedData The filtered array returned from filterData()
 * @returns {Array} a normalized array of data
 */
export const draw = (normalizedData: number[], canvas: HTMLCanvasElement) => {
  // set up the canvas
  canvas.width = canvas.offsetWidth
  canvas.height = canvas.offsetHeight

  const ctx = canvas.getContext('2d')
  if (ctx === null) return
  ctx.scale(1.0, 1.0)
  ctx.translate(0.5, 0.5) // https://www.rgraph.net/canvas/howto-antialias.html

  // draw the line segments

  const width = canvas.width / normalizedData.length

  for (let i = 0; i < normalizedData.length; i++) {
    const x = Math.round(width * i)
    const newX = Math.round(width * (i + 1))
    drawLineSegment(
      ctx,
      x,
      normalizedData[i],
      newX - x,
      canvas.height,
      '#0EA5E9',
    )
  }
}

/**
 * A utility function for drawing our line segments
 * @param {CanvasRenderingContext2D} context the drawing context
 * @param {number} x  the x coordinate of the beginning of the line segment
 * @param {number} height the desired height of the line segment
 * @param {number} width the desired width of the line segment
 */
const drawLineSegment = (
  context: CanvasRenderingContext2D,
  x: number,
  value: number,
  width: number,
  canvasHeight: number,
  color: string,
) => {
  let height = value * canvasHeight
  if (height < 0) {
    height = 0
  } else if (height > canvasHeight) {
    height = canvasHeight
  }

  context.fillStyle = color
  context.fillRect(x, canvasHeight - height, width, height)
}
