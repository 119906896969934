import { LabelKey, getHardLabelConfigFromTitle } from 'common/ontology'
import { LabelsPagination, Range } from 'common/types'
import { useCallback, useRef, useState } from 'react'
import { RangeSlider } from './RangeSlider'
import { useHorizontalDrag } from './hooks/useHorizontalDrag'

type Props = {
  audioElementRef: React.RefObject<HTMLAudioElement>
  ranges: Range[]
  onRangeChanges: React.Dispatch<React.SetStateAction<Range[]>>
  labelsPagination: LabelsPagination
  pendingHardLabel: LabelKey | null
  audioDuration: number
}

export const RangesEditor = ({
  audioElementRef,
  ranges,
  onRangeChanges,
  labelsPagination,
  pendingHardLabel,
  audioDuration,
}: Props) => {
  const [newRange, setNewRange] = useState<Range | null>(null)
  const progressionTrackRef = useRef<HTMLDivElement>(null)

  const handleRangeChange = useCallback(
    (range: Range, start: number, end: number) => {
      if (start === end) {
        onRangeChanges((prevRanges) => {
          const rangeIndex = prevRanges.indexOf(range)
          prevRanges.splice(rangeIndex, 1)
          return [...prevRanges]
        })
      } else {
        range.start = start
        range.end = end
        onRangeChanges((prevRanges) => [...prevRanges])
      }
    },
    [onRangeChanges],
  )

  function interp(x: number, trackRect: DOMRect) {
    const value = (audioDuration * x) / trackRect.width
    return Math.min(Math.max(value, 0), audioDuration)
  }

  const trackDragHandlers = useHorizontalDrag(progressionTrackRef, {
    onClick: (x: number) => {
      if (
        progressionTrackRef.current === null ||
        audioElementRef.current === null
      )
        return
      const trackRect = progressionTrackRef.current.getBoundingClientRect()
      const audioElement = audioElementRef.current

      audioElement.currentTime = (audioDuration * x) / trackRect.width
    },
    onDrag: ({ start, end }) => {
      if (progressionTrackRef.current === null) return
      const trackRect = progressionTrackRef.current.getBoundingClientRect()

      const rangeStart = interp(start, trackRect)
      const rangeEnd = interp(end, trackRect)

      setNewRange({
        start: Math.min(rangeStart, rangeEnd),
        end: Math.max(rangeStart, rangeEnd),
      })
    },
    onEnd: ({ start, end }) => {
      if (progressionTrackRef.current === null) return
      const trackRect = progressionTrackRef.current.getBoundingClientRect()

      const rangeStart = interp(start, trackRect)
      const rangeEnd = interp(end, trackRect)

      if (rangeStart !== rangeEnd) {
        const newRange: Range = {
          start: Math.min(rangeStart, rangeEnd),
          end: Math.max(rangeStart, rangeEnd),
        }
        if (pendingHardLabel !== null) newRange.label = pendingHardLabel
        onRangeChanges([...ranges, newRange])
      }

      setNewRange(null)
    },
  })

  return (
    <div
      className="absolute bottom-0 left-0 right-0 top-0 z-10 flex"
      ref={progressionTrackRef}
      {...trackDragHandlers}
    >
      {newRange && (
        <RangeSlider
          trackRef={progressionTrackRef}
          range={newRange}
          duration={audioDuration}
          color={
            pendingHardLabel !== null
              ? getHardLabelConfigFromTitle(labelsPagination, pendingHardLabel)
                  .color
              : 'bg-black'
          }
          text={pendingHardLabel !== null ? pendingHardLabel : '🔇'}
        ></RangeSlider>
      )}
      {ranges.map((range, range_index) => (
        <RangeSlider
          key={range_index}
          trackRef={progressionTrackRef}
          range={range}
          duration={audioDuration}
          color={
            range.label !== undefined
              ? getHardLabelConfigFromTitle(labelsPagination, range.label).color
              : 'bg-black'
          }
          text={range.label !== undefined ? range.label : '🔇'}
          onChange={handleRangeChange}
        ></RangeSlider>
      ))}
    </div>
  )
}
