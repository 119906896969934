export const MIN_VOL = 0.1
export const MAX_VOL = 1
export const MAX_AMPLITUDE = 0.2

function computeHighAverage(audioBuffer: AudioBuffer) {
  const channelData = audioBuffer.getChannelData(0)
  const sliceLen = Math.floor(audioBuffer.sampleRate * 0.05)
  const averages = []
  let sum = 0
  for (let i = 0; i < channelData.length; i++) {
    sum += channelData[i] ** 2
    if (i % sliceLen === 0) {
      sum = Math.sqrt(sum / sliceLen)
      averages.push(sum)
      sum = 0
    }
  }
  // Ascending sort of the averages array
  averages.sort((a, b) => a - b)
  // Take the average at the 95th percentile
  return averages[Math.floor(averages.length * 0.95)]
}

export function computeAdmissibleVolume(audioBuffer: AudioBuffer) {
  const highAverage = computeHighAverage(audioBuffer)
  return Math.min(Math.max(1 - highAverage / MAX_AMPLITUDE, MIN_VOL), MAX_VOL)
}
