import { LABEL_TEXT, LabelKey } from 'common/ontology'
import { HardLabelConfig } from 'common/types'
import { useMemo } from 'react'
import { Button } from '../components'

interface Props {
  pendingHardLabel: LabelKey | null
  onClick: (label: LabelKey) => void
  hardLabelConfig: HardLabelConfig
}

export const HardLabelButton = ({
  pendingHardLabel,
  onClick,
  hardLabelConfig: config,
}: Props) => {
  const isPending = useMemo(() => {
    return pendingHardLabel === config.title
  }, [pendingHardLabel, config])

  return (
    <Button
      onClick={() => onClick(config.title)}
      primary={isPending}
      primaryColor={config.color}
    >
      {LABEL_TEXT[config.title]}
    </Button>
  )
}
