import { DateCount } from './Stats'

export const StatsTable: React.FC<{
  dateCount: DateCount
  columns: string[]
}> = ({ dateCount, columns }) => {
  return (
    <table className="table-auto">
      <thead>
        <tr>
          {columns.map((column_name) => (
            <th
              key={column_name}
              className="border bg-slate-800 bg-opacity-50 p-2 text-center"
            >
              {column_name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Object.entries(dateCount).map(([date, count]) => {
          return (
            <tr key={`${date}_${count}`}>
              <td key={date} className="border p-2 text-left">
                {date}
              </td>
              <td key={count} className="border p-2 text-left">
                {count.toFixed(0)}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
