import { tryToReplaceUid } from 'common/tryToReplaceUid'
import { OntologyType } from 'common/types'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import {
  AsyncState,
  dataState,
  errorState,
  loadingState,
} from 'shared/types/asyncState'
import { DateString } from 'shared/types/utils'
import { isObjectEmpty } from 'shared/utils/defined'
import { getPreviousDates } from 'shared/utils/timeRange'
import { onError } from 'shared/utils/web/error'
import { StatsTable } from './StatsTable'
import { auth } from './firebase'
import { get } from './firebaseMethods'

export type DateCount = Record<DateString, number>

type Data = {
  dateCount: DateCount
  totalLabeledSounds: number | undefined
  totalSoundsInQueue: number | undefined
}

type StatsConfig = {
  displayProgress: boolean
}

const statsConfigMap: Record<OntologyType, StatsConfig> = {
  layer1: {
    displayProgress: false,
  },
  demo: {
    displayProgress: true,
  },
  respiration: {
    displayProgress: true,
  },
  keyword: {
    displayProgress: false,
  },
  unet: {
    displayProgress: false,
  },
}

export const Stats = () => {
  const displayProgress =
    statsConfigMap[import.meta.env.VITE_ONTOLOGY].displayProgress
  const [state, setState] = useState<AsyncState<Data>>(loadingState())

  const user = auth.currentUser

  useEffect(() => {
    async function fetchLabelerStats() {
      if (user === null) return
      try {
        const NB_DAYS_HISTORY = 30
        const now = DateTime.now().toISODate()
        const dates = getPreviousDates(now, NB_DAYS_HISTORY)

        const dateStats = await Promise.all(
          dates.map((date) => get(`stats/${user.uid}/${date}`)),
        )

        const signUpDate = new Date(user?.metadata?.creationTime ?? 0)
          .toISOString()
          .split('T')[0]

        const data: Data = {
          dateCount: {},
          totalLabeledSounds: undefined,
          totalSoundsInQueue: undefined,
        }

        if (displayProgress && user !== null) {
          const sounds = await get(`sounds`)
          data.totalLabeledSounds = Object.values(sounds).filter((sound) =>
            (sound.labelerUids ?? []).includes(user.uid),
          ).length
          data.totalSoundsInQueue = Object.keys(sounds).length
        }

        for (const [idx, stats] of dateStats.entries()) {
          const date = dates[idx]
          const count = stats?.count ?? 0
          if (date >= signUpDate) data.dateCount[date] = count
        }

        setState(dataState(data))
      } catch (error) {
        setState(errorState(new Error(`An error occured`)))
        onError(error)
      }
    }

    fetchLabelerStats()
  }, [user, displayProgress])

  if (user === null) {
    return (
      <div className="flex min-h-screen flex-col justify-center text-center">
        Utilisateur inconnu
      </div>
    )
  }

  const userName = tryToReplaceUid(user.uid, user?.email)

  return (
    <div className="flex min-h-screen w-screen grow flex-col p-8">
      <div className="text-4xl font-bold">{`Statistiques | ${userName}`}</div>
      {state.loading ? (
        <div className="flex min-h-screen flex-col justify-center text-center">
          Chargement...
        </div>
      ) : state.error ? (
        <div className="flex min-h-screen flex-col justify-center text-center">
          Une erreur est survenue
        </div>
      ) : !isObjectEmpty(state.data) ? (
        <div className="flex flex-col gap-8 pt-8">
          {displayProgress && (
            <div className="flex flex-col">
              {`${state.data.totalLabeledSounds} labellisations réalisées / ${state.data.totalSoundsInQueue} sons disponibles dans l'application`}
            </div>
          )}
          <StatsTable
            dateCount={state.data.dateCount}
            columns={['Date', 'Nombre de labellisations']}
          />
        </div>
      ) : (
        <div>Aucune labellisation réalisée</div>
      )}
    </div>
  )
}
