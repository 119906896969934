import { useEffect } from 'react'
import { Button } from './components'

type Props = {
  children: React.ReactNode
  totalPages: number
  currentPageIndex: number
  onNextPageClick: () => void
  onFirstPageClick: () => void
  onPreviousPageClick: () => void
}

export const PaginationWrapper = ({
  children,
  totalPages,
  currentPageIndex,
  onNextPageClick,
  onFirstPageClick,
  onPreviousPageClick,
}: Props) => {
  const isNextDisabled = currentPageIndex === totalPages - 1
  const isPreviousDisabled = currentPageIndex === 0
  // Keypress handler
  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      // Play / Pause
      if (!isNextDisabled && (e.key === 'v' || e.key === 'V')) {
        onNextPageClick()
      }

      if (!isPreviousDisabled && (e.key === 'c' || e.key === 'C')) {
        onPreviousPageClick()
      }
    }

    window.addEventListener('keyup', handleKeyUp)
    return () => {
      window.removeEventListener('keyup', handleKeyUp)
    }
  })

  return (
    <div className="flex flex-auto flex-col p-4">
      <div className="flex grow flex-row flex-wrap justify-evenly gap-4 lg:items-center lg:px-20 lg:py-4">
        {children}
      </div>
      {totalPages > 1 && (
        <div className="flex flex-row items-center justify-end gap-4">
          <Button
            title="Page précédente (C)"
            onClick={onPreviousPageClick}
            disabled={isPreviousDisabled}
          >
            &lt;
          </Button>
          <span>{`${currentPageIndex + 1} / ${totalPages}`}</span>
          <Button
            primary
            title="Page suivante (V)"
            onClick={onNextPageClick}
            disabled={isNextDisabled}
          >
            Valider &gt;
          </Button>
          <Button
            className="hover:bg-yellow-500 hover:bg-opacity-100"
            title="Retour à la première page"
            disabled={currentPageIndex !== totalPages - 1}
            onClick={onFirstPageClick}
          >
            ↩️
          </Button>
        </div>
      )}
    </div>
  )
}
