import { addOggExtension } from 'common/soundKey'
import { Role } from 'common/types'
import { useEffect, useState } from 'react'
import { labelingConfigMap } from './Labeling'
import { Player } from './Player'
import { Button } from './components'
import { getFilePathTime } from './utils'

interface Props {
  getUrl: (path: string) => Promise<string>
  enableLabeling: () => void
  role: Role | undefined
}

export const Listener = ({ getUrl, enableLabeling, role }: Props) => {
  const { playbackRates } = labelingConfigMap[import.meta.env.VITE_ONTOLOGY]

  // first is for ?listen=, second case is for root URL '/'
  const path =
    window.location.href.split('=')[1] ??
    window.location.pathname.replace(/^\//, '')

  const [soundUrl, setSoundUrl] = useState<string | undefined>(undefined)

  useEffect(() => {
    async function fetchUrl() {
      const soundUrl = await getUrl(addOggExtension(path))
      setSoundUrl(soundUrl)
    }

    fetchUrl()
  }, [path, getUrl])

  const labelTime = getFilePathTime(path)

  return (
    <div className="flex w-screen flex-col">
      {soundUrl && (
        <Player
          labelTime={labelTime}
          src={soundUrl}
          autoplay={true}
          mutedRanges={[]}
          playbackRates={playbackRates}
        ></Player>
      )}
      <div className={`flex flex-row justify-center`}>
        <Button primary onClick={enableLabeling} disabled={!role}>
          Labelliser ce son
        </Button>
      </div>
    </div>
  )
}
