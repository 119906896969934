import { LABEL_DESCRIPTION, LABEL_TEXT, LabelKey } from 'common/ontology'
import { LabelsGroupConfig } from 'common/types'
import { useMemo } from 'react'
import { Button, defaultColor, defaultPrimaryColor } from '../components'

interface Props {
  activatedLabels: Partial<Record<LabelKey, LabelKey[]>>
  labelsGroupConfig: LabelsGroupConfig
  onLabelsGroupClick: (label: LabelKey) => void
  onSubLabelClick: (label: LabelKey, subLabel: LabelKey) => void
  popUpHandler: (labelsGroupTitle: LabelKey) => Promise<void>
}

export const LabelsGroupButton = ({
  activatedLabels,
  labelsGroupConfig,
  onLabelsGroupClick,
  onSubLabelClick,
  popUpHandler,
}: Props) => {
  const labelsGroupIsLabel = !labelsGroupConfig.isNotLabel

  const isLabelGroupActivated = useMemo(
    () => activatedLabels[labelsGroupConfig.title] !== undefined,
    [activatedLabels, labelsGroupConfig],
  )

  const activatedSubLabels = useMemo(
    () => activatedLabels[labelsGroupConfig.title] ?? [],
    [activatedLabels, labelsGroupConfig],
  )

  return (
    <div className="flex flex-col">
      <div className="has-tooltip flex flex-col items-center">
        {labelsGroupConfig.subLabels ? (
          <div
            className={`tooltip bg-default mt-16 max-w-lg rounded-xl border-4 p-2`}
          >
            <div className={`flex flex-col gap-2`}>
              {LABEL_DESCRIPTION[labelsGroupConfig.title] ? (
                <div
                  className={`rounded-md bg-sky-800 text-center text-lg font-bold italic`}
                >
                  {LABEL_DESCRIPTION[labelsGroupConfig.title]}
                </div>
              ) : null}
              <div className={`flex flex-row flex-wrap justify-center gap-2`}>
                {labelsGroupConfig.subLabels.map((subLabel) => (
                  <Button
                    key={subLabel}
                    className="text-base"
                    onClick={() => {
                      onSubLabelClick(labelsGroupConfig.title, subLabel)
                    }}
                    primary={activatedSubLabels.includes(subLabel)}
                  >
                    {LABEL_TEXT[subLabel]}
                  </Button>
                ))}
              </div>
            </div>
          </div>
        ) : null}

        <Button
          data-tooltip-target="tooltip-default"
          className="flex flex-row items-center gap-4"
          primary={isLabelGroupActivated && labelsGroupIsLabel}
          onClick={() => {
            if (labelsGroupIsLabel || activatedSubLabels.length != 0)
              onLabelsGroupClick(labelsGroupConfig.title)
            if (!isLabelGroupActivated) {
              if (labelsGroupConfig.subLabels)
                popUpHandler(labelsGroupConfig.title)
            }
          }}
        >
          <span>{LABEL_TEXT[labelsGroupConfig.title]}</span>
          {labelsGroupConfig.subLabels && (
            <div
              className={`pointer-events-none rounded-full border-2 px-3 py-1 text-sm ${
                activatedSubLabels.length !== 0
                  ? defaultPrimaryColor
                  : defaultColor
              }`}
            >
              {activatedSubLabels.length}
            </div>
          )}
        </Button>
      </div>
    </div>
  )
}
