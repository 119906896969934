import { LABEL_DESCRIPTION, LABEL_TEXT, LabelKey } from 'common/ontology'
import { LabelsGroupConfig } from 'common/types'
import React, { useMemo } from 'react'
import { Deferred } from 'shared/utils/web/deferred'
import { Button } from '../components'
import { DIALOG_CLOSED_REASON, Dialog } from '../components/Dialog'

export const SubLabelsDialog: React.FC<{
  activatedLabels: Partial<Record<LabelKey, LabelKey[]>>
  subLabelsDialogData: {
    labelsGroupConfig: LabelsGroupConfig
    deferred: Deferred<void>
  }
  onClose: (label: LabelKey) => void
  onSubLabelClick: (label: LabelKey, subLabel: LabelKey) => void
}> = ({ activatedLabels, subLabelsDialogData, onClose, onSubLabelClick }) => {
  const { labelsGroupConfig, deferred } = subLabelsDialogData
  const handleClose = () => {
    onClose(labelsGroupConfig.title)
    deferred.reject(DIALOG_CLOSED_REASON)
  }

  const activatedSubLabels = useMemo(
    () => activatedLabels[labelsGroupConfig.title] ?? [],
    [activatedLabels, labelsGroupConfig],
  )

  return (
    <Dialog
      title={LABEL_TEXT[labelsGroupConfig.title]}
      onClose={() => handleClose()}
    >
      <div className="flex flex-col gap-4 pt-4">
        {LABEL_DESCRIPTION[labelsGroupConfig.title] ? (
          <div
            className={`rounded-md bg-sky-800 text-center text-lg font-bold italic`}
          >
            {LABEL_DESCRIPTION[labelsGroupConfig.title]}
          </div>
        ) : null}
        <div className="flex flex-row flex-wrap items-center justify-center gap-2">
          {(labelsGroupConfig.subLabels || []).map((subLabel) => (
            <Button
              className="grow"
              primary={activatedSubLabels.includes(subLabel)}
              key={subLabel}
              onClick={() => onSubLabelClick(labelsGroupConfig.title, subLabel)}
            >
              {LABEL_TEXT[subLabel]}
            </Button>
          ))}
        </div>
        <Button
          primary={true}
          onClick={() => {
            deferred.resolve()
          }}
        >
          Valider
        </Button>
      </div>
    </Dialog>
  )
}
