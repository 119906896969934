import React, { ChangeEvent, useEffect, useState } from 'react'
import { Deferred } from 'shared/utils/web/deferred'
import { Button, TextArea } from '../components'
import { push } from '../firebaseMethods'
import { useFirebase } from '../hooks/useFirebase'
import { DIALOG_CLOSED_REASON, Dialog } from './Dialog'

export const ReviewCommentDialog: React.FC<{
  deferred: Deferred<string>
}> = ({ deferred }) => {
  const [text, setText] = useState<string>('')
  const [selectedComment, setSelectedComment] = useState<string | undefined>(
    undefined,
  )
  const [allowCommentCreation, setAllowCommentCreation] =
    useState<boolean>(false)

  const { data: reviewComments, loading } = useFirebase('reviewComments')

  const normalizeText = (text: string) => {
    return text
      .trim()
      .replace(/-/g, '_')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // Remove accents
      .replace(/[^\w\s']/g, '') // Remove punctuation
      .replace(/ /g, '_')
      .toLowerCase()
  }

  useEffect(() => {
    const normalizedComment = normalizeText(text)
    if (text.trim().length === 0) {
      setAllowCommentCreation(false)
      return
    }

    if (
      Object.values(reviewComments || {}).some(
        (existingComment) =>
          normalizeText(existingComment) === normalizedComment,
      )
    ) {
      setAllowCommentCreation(false)
      return
    }

    setAllowCommentCreation(true)
  }, [text, reviewComments])

  const saveNewComment = (comment: string) => {
    push('reviewComments', comment)
  }

  return (
    <Dialog
      title="Ajouter un commentaire"
      onClose={() => deferred.reject(DIALOG_CLOSED_REASON)}
    >
      <div className="flex max-w-lg flex-col gap-4 pt-4">
        {selectedComment === undefined ||
        selectedComment.trim().length === 0 ? (
          <>
            <TextArea
              value={text}
              rows={4}
              placeholder={`Rechercher un commentaire existant ou en créer un nouveau`}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                setText(e.currentTarget.value)
              }
            />
            <div className="flex max-h-96 flex-col gap-2 overflow-auto pr-2">
              {allowCommentCreation && (
                <Button
                  className="bg-slate-700 hover:bg-slate-500"
                  onClick={() => {
                    saveNewComment(text)
                    setSelectedComment(text)
                  }}
                >
                  {`Créer : "${text}"`}
                </Button>
              )}
              {loading ? (
                <span className="text-center">Chargement...</span>
              ) : (
                Object.values(reviewComments || {})
                  .sort((existingCommentA, existingCommentB) =>
                    existingCommentA.localeCompare(existingCommentB),
                  )
                  .filter((existingComment) =>
                    text.trim().length !== 0
                      ? normalizeText(existingComment).includes(
                          normalizeText(text),
                        )
                      : true,
                  )
                  .map((existingComment) => (
                    <Button
                      className="bg-slate-700 hover:bg-slate-500"
                      key={existingComment}
                      onClick={() => setSelectedComment(existingComment)}
                    >
                      {existingComment}
                    </Button>
                  ))
              )}
            </div>
          </>
        ) : (
          <>
            <span className="p-8 text-center font-bold">{selectedComment}</span>
            <Button
              className="bg-slate-700 hover:bg-slate-500"
              onClick={() => {
                setText('')
                setSelectedComment(undefined)
              }}
            >
              Effacer
            </Button>
          </>
        )}
        <div className="flex flex-row gap-3">
          <Button
            className="flex flex-1 justify-center"
            onClick={() => deferred.reject(DIALOG_CLOSED_REASON)}
          >
            Annuler
          </Button>
          <Button
            className="flex flex-1 justify-center"
            disabled={text.trim().length !== 0 && !selectedComment}
            onClick={() =>
              deferred.resolve(selectedComment ? selectedComment : '')
            }
          >
            Valider
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
